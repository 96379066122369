// see https://github.com/webpack/webpack/issues/4258
jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

moment = require('moment-timezone');
require('moment/locale/es.js');
require('moment/locale/pt-br.js');

require('jquery-ui/ui/tabs.js');
require('jquery-ui/ui/autocomplete.js');
require('jquery-ui/ui/button.js');
require('jquery-ui/ui/datepicker.js');
require('jquery-ui/ui/dialog.js');
require('jquery-ui/ui/menu.js');
require('jquery-ui/ui/widget.js');
require('jquery-ui/ui/sortable.js');
require('jquery-ui/ui/resizable.js');

require('jquery-ui/ui/i18n/datepicker-es.js');
require('jquery-ui/ui/i18n/datepicker-pt-BR.js');

d3 = require('d3');
nv = require('nvd3');

require('../../../vendor/assets/javascripts/jquery-ui-timepicker-addon');
require('../../../vendor/assets/javascripts/jquery-ui-timepicker-es');
require('../../../vendor/assets/javascripts/jquery-ui-timepicker-pt-BR');
MegaMenu = require('../../../vendor/assets/javascripts/mega-menu');

require('@claviska/jquery-dropdown');
toastr = require('toastr');
require('spectrum-colorpicker/spectrum');
